<template>
    <div class="bidding">
        <div class="main">
            <!--            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"-->
            <!--                     text-color="#666"-->
            <!--                     active-text-color="#e34545">-->
            <!--                <el-menu-item index="1" @click="gotXQlist">返回咨询中心</el-menu-item>-->
            <!--                <el-menu-item v-if="title == 'public'"  @click="getNoticelist">返回实时公告</el-menu-item>-->
            <!--                <el-menu-item v-if="title == 'info'" @click="getXQlist">返回实时资讯</el-menu-item>-->
            <!--            </el-menu>-->
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/zixun' }">资讯中心</el-breadcrumb-item>
                <el-breadcrumb-item v-if="title == 'public'" :to="{ path: '/NoticeList?'+ 'modes=' + 'Bidding'}">实时公告</el-breadcrumb-item>
                <el-breadcrumb-item v-if="title == 'public'">公告详情</el-breadcrumb-item>
                <el-breadcrumb-item  v-if="title == 'info'" :to="{ path: '/XiangQingList'}">实时资讯</el-breadcrumb-item>
                <el-breadcrumb-item  v-if="title == 'info'">资讯详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-card class="box-card" v-if="title == 'info'">
                <div class="main-title">{{ newsReleaseName }}</div>
                <div class="main-body">
                    <div class="main-center">
                        <!-- <el-input type="textarea" :rows="10" readonly v-show="iframeState" class="main_iframe" name="showHere" :value="url"></el-input> -->
                          <p v-html="url"></p>
                        <!-- <iframe class="main_iframe" v-show="iframeState"  frameborder=0 name="showHere" scrolling=auto :src="url"></iframe> -->
                    </div>
                </div>
            </el-card>
            <el-card class="box-card" v-if="title == 'public'">
                <div class="main-title">{{ bidname }}
                    <router-link  style="margin-left: 65%;font-size: 18px;font-weight: bold"
                                  :to="{ path: '/auction', query: {targetid:dataForm.targetid}}">
                        <el-button style="font-size: 18px;font-weight: bold" v-if="dataForm.outbidstate === '0'">竞价结果：已拍下</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="dataForm.outbidstate === '1'">竞价结果：已流拍</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="dataForm.outbidstate === '2'">竞价结果：已关闭</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="dataForm.outbidstate === '3'">竞价结果：进行中（或等待竞拍结果）</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="dataForm.outbidstate === '4'">竞价状态：准备中</el-button>
                    </router-link>
                </div>

                <div class="main-body">
                    <div class="main-center">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item name="1">
                                <template slot="title" >
                                    <div style="font-size: 20px;font-weight: bolder">竞卖公告</div>
                                </template>
                                <el-card>
                                    <div class="w-100 h-100 item-8-details">
                                        <div class="item-8-title">中煤销售太原有限公司竞价事项说明</div>
                                        <div class="item-8-title">({{bidname}})</div>
                                        <div class="item-8-title" style="font-weight: bolder">承诺函</div>
                                        <div style="font-weight: bolder">一、综合说明</div>
                                        <div style="font-weight: bolder">第一场</div>
                                        <div>1、竞价物资：{{productInformation[0].productname}}</div>
                                        <div>2、竞价单位：</div>
                                        <div v-for="(item,index) in participate" :key="index">
                                            <p style="text-indent:1.6em;">{{item.enterprisename}}</p>
                                        </div>
                                        <div>3、竞价方式：{{this.biddingRules[0].data01}}</div>
                                        <div>4、竞价期间：{{detailsStartTime}}至{{detailsEndTime}}</div>
                                        <div>5、供货期限：暂定{{transactionAttributes[0].trtime}}，在此期间内，具体发货时间以实际销售安排为准。</div>
                                        <div>6、竞价商品基本信息:
                                            <div>
                                                <el-table :data="transactionNoticeTemp" class="w-100" border
                                                          :header-cell-style="productInformationStyle">
                                                    <el-table-column label="竞价物资" align="center" prop="productname"></el-table-column>
                                                    <el-table-column label="产地" align="center" prop="origin"></el-table-column>
                                                    <el-table-column label="交割地" align="center" prop="origindetail"></el-table-column>
                                                    <!--                      <el-table-column label="提货方式" align="center" prop="delivertype"></el-table-column>-->
                                                    <el-table-column label="数量（吨）" align="center" prop="quantity"></el-table-column>
                                                    <!--                      <el-table-column label="付款方式" align="center" prop="payment"></el-table-column>-->
                                                    <el-table-column label="竞拍基价（元/吨）" align="center" prop="price"></el-table-column>
                                                </el-table>
                                            </div>
                                        </div>
                                        <div>7、交易产品参考质量指标:
                                            <el-table :data="productInformation" class="w-100" border
                                                      :header-cell-style="productInformationStyle">
                                                <el-table-column label="更多信息" align="center">
                                                    <template>
                                                        <span>{{pdescription}}</span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>

                                        <div>
                                            <div style="font-weight: bolder">二、竞价内容及说明：</div>
                                            <div>1、实行履约诚意金制度</div>
                                            <div>(1)为维护交易双方的合法权益，参加竞价客户(以下简称“买方”)须缴纳诚意金5万元，需公户付款，不接受个人缴款和现金入账。</div>
                                            <div>(2)买方需在开始竞拍前1小时，一次性缴纳诚意金5万元，诚意金不能冲抵煤款，逾期未足额缴纳诚意金的企业将无法参与竞价交易。诚意金账户信息：</div>
                                            <div>开户行：中国建设银行太原高新技术园区支行</div>
                                            <div> 账  号：1405 0182 6408 0951 8899</div>
                                            <div>开户单位：中煤销售太原有限公司</div>
                                            <div>(3) 竞价交易成功后，买方在两个工作日内签订煤炭买卖合同，全额支付货款。如果买方在规定时间内未签订合同，支付货款，将视为违约，中煤销售太原有限公司将罚没履约诚意金。</div>
                                            <div>(4)参与竞价的用户均须严格遵守本交易平台的交易办法、交易流程和交易制度，并严格按照交易规则办理诚意金的提交手续和退还手续。本交易平台对参与竞价用户提交的诚意金不支付任何利息。</div>
                                            <div>2、竞卖加价：每次报价的最小竞卖加价为1元/吨，基价可以作为挂牌价格。</div>
                                            <div>3、交货地点及方式：在王家岭矿场地买方自提。煤炭运杂费用、运输过程中的安全问题及意外事件由买方自己承担。</div>
                                            <div> 4、结算方式：全额现汇预付货款。</div>
                                            <div> 5、质量和数量的验收标准及方法：质量以王家岭矿化验结果为准，数量以王家岭矿过衡计量数量为结算依据。</div>
                                            <div style="font-weight: bolder">三、注意事项：</div>
                                            <div> 1、报名方式：进入首页，查看到“公开销售”中竞卖信息，点击竞卖信息，进入登录页面，输入账号和密码登录系统（默认密码123456，登录系统后请及时修改密码，妥善保管自己的账号和密码），没有账号用户请进行注册。选择竞卖--等待竞卖--报名。</div>
                                            <div>2、在线竞价方式：凭注册的用户名及密码登录系统选择竞卖--竞卖中--参与竞价。</div>
                                            <div> 3、竞价结果查询：凭注册用户的微信登录系统选择“我的”--竞卖订单--查看竞价结果。</div>
                                            <div>4、通过本交易平台竞价程序取得成交资格的交易商违约的，将不予退还已经提交的诚意金，竞价标的物将由本交易平台另行处理。请诸位理性出价。</div>
                                            <div> 5、如经本交易平台利用技术手段掌握个别交易商在竞价过程中存在“参与方恶意串通、人为控制交易价格”的行为，则本交易平台有权立即中止竞价或取消竞价。</div>
                                            <div>6、如因黑客攻击、系统故障、服务器故障等原因，导致竞价中断或无法正常进行的，则该场次的竞价程序无效，且本交易平台有权对该场次竞价全权进行处理，任何交易商均无权干涉。</div>
                                            <div>7、请妥善保管自己的账号和密码，严禁对外泄露，该帐号下发生的所有竞拍行为平台视为有效，客户自行承担全部责任。</div>
                                            <div style="font-weight: bolder"> 四、其他：</div>
                                            <div>1、该竞价解释权归中煤销售太原有限公司所有。</div>
                                            <div>  2、联系方式 <div>
                                                联系人：杨科长                                          联系方式：15934048233
                                            </div>
                                                <div style="text-align: right">中煤销售太原有限公司</div>
                                                <div style="text-align: right">{{detailsCreatTime}}</div>
                                            </div>
                                            <el-button @click="exportWordNotice" type="primary" style="float: right;margin-bottom: 5px;">导出word</el-button>
                                        </div>
                                    </div>
                                </el-card>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import JSZipUtils from "jszip-utils";
    import docxtemplater from "docxtemplater"
    import {saveAs} from 'file-saver'
    import JSZip from 'jszip'
    export default {
        data() {
            return {
                // modes:'',
                activeNames: '1',
                iframeState: true,
                url: '',
                newsReleaseName: '',
                title: '',
///////////////////////////////////////////////
                //    //////////////////////////////竞卖公告/////////////////////////////////
                dataList: [],
                dataForm: {
                    targetid: "",
                    splitstate: "",
                    outbidstate: ""
                },
                bidname:'',
                //竞价结果table数据
                participate: [],
                //交易属性table数据
                transactionAttributes: [
                    {
                        data01: '--', deposit: '', depositendtime: '', deliverydetail: '', delivery: '',origin:'',origindetail:'',
                        trtime: '', data07: '--', data08: '--',
                    }
                ],
                //交易属性table竞价商品基本信息:
                transactionNoticeTemp:[],
                transactionNotice: {
                    pproductname: '',
                    delivery: '',
                    delivertype: '',
                    minquantity: '',
                    payment: '',
                    price: '',
                },
                //开始时间
                detailsStartTime: '',
                //结束时间
                detailsEndTime: '',
                //发布时间
                detailsCreatTime: '',
                //table表头样式
                productInformationStyle: {
                    'font-size': '11px',
                    'background': 'whitesmoke',
                    'text-align': 'center',
                    'color': '#333333',
                    'font-family': 'Microsoft YaHei',
                    'font-weight': 'normal',
                },

                //竞价规则table数据
                biddingRules: [
                    {
                        data01: '公开竞价', begintime: '', endtime: '', data04: '否',
                        data05: '--',
                        data06: '--', data07: '按自报价',
                    }
                ],
                //产品信息table数据
                productInformation: [
                    {
                        productname: '',
                        origin: '',
                        quantity: '',
                        minbquantity: '',
                        minquantity: '',
                        price: '',
                        minmarkup: '',
                    }
                ],
                //产品详情
                pdescription: "",

            }
        },
        activated(){
            this.url=this.$route.query.url;
            this.newsReleaseName=this.$route.query.newsReleaseName;
            this.bidname=this.$route.query.bidname;
            this.title=this.$route.query.title;
            if( this.title == 'public'){
                this.getDataList();
            }
            this.dataForm.targetid = this.$route.query.targetid;
        },
        // mounted() {
        //     // this.dataForm.outbidstate= '';
        //     this.getDataList();
        // },

        methods: {
            //时间转换
            getDetailsTime() {
                let start = this.biddingRules[0].begintime
                let end = this.biddingRules[0].endtime
                this.toNyr(start)
                this.detailsStartTime = this.toNyr(start);
                this.detailsEndTime = this.toNyr(end);
                let newDate = end.split(' ');
                let day = newDate[0].split('/');
                this.detailsCreatTime = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日';
            },
            toNyr(date) {
                let newDate = date.split(' ');
                let day = newDate[0];
                let time = newDate[1];
                day = day.split('/');
                time = time.split(':');
                let newDay = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日 ';
                let newTime = time[0] + '时 ' + time[1] + '分 ' + time[2] + '秒';
                return newDay + newTime;
            },
            // //公告详情
            // gotoDetails(targetid) {
            //     let queryData = {};
            //     queryData.targetid = targetid;
            //     this.$router.push({path: "/auction", query: queryData});
            // },
            //获取商品信息
            getDataList(){
                this.$post('pctargetinfo/detail', this.dataForm).then((data) => {
                    if (data && data.code === 0) {
                        this.dataForm.outbidstate = '';
                        this.bidname = data.targetdetail.bidname;
                        this.dataForm.splitstate = data.targetdetail.splitstate;
                        this.dataForm.outbidstate = data.targetdetail.outbidstate;
                        this.productInformation[0].productname = data.productdetail.productname;
                        this.productInformation[0].origin = data.productdetail.origin;
                        this.productInformation[0].quantity = data.targetdetail.quantity;
                        this.productInformation[0].minbquantity = data.targetdetail.minbquantity;
                        this.productInformation[0].minquantity = data.targetdetail.minquantity;
                        this.productInformation[0].price = data.targetdetail.price;
                        this.productInformation[0].minmarkup = data.targetdetail.minmarkup;
                        this.transactionAttributes[0].deposit = data.targetdetail.deposit;
                        this.transactionAttributes[0].depositendtime = data.targetdetail.depositendtime;
                        this.transactionAttributes[0].deliverydetail = data.targetdetail.deliverydetail;
                        this.transactionAttributes[0].delivery = data.targetdetail.delivery;
                        this.transactionAttributes[0].trtime = "从" + data.targetdetail.trbegintime + "至" + data.targetdetail.trendtime;
                        this.biddingRules[0].begintime = data.targetdetail.begintime;
                        this.biddingRules[0].endtime = data.targetdetail.endtime;
                        this.biddingTime = data.targetdetail.biddingTime;
                        //公告竞价商品基本信息
                        this.transactionNotice.productname=data.productdetail.productname;
                        this.transactionNotice.delivery=data.targetdetail.delivery;
                        this.transactionNotice.delivertype=data.targetdetail.delivertype;
                        this.transactionNotice.minquantity=data.targetdetail.minquantity;
                        this.transactionNotice.payment=data.targetdetail.payment;
                        this.transactionNotice.price=data.targetdetail.price;
                        this.transactionNotice.quantity=data.targetdetail.quantity;
                        this.transactionNotice.origin=data.productdetail.origin;
                        this.transactionNotice.origindetail=data.productdetail.origindetail;
                        if (this.transactionNoticeTemp.length == 0) {
                            this.transactionNoticeTemp.push(this.transactionNotice);
                        }
                        // this.description = data.targetdetail.description;
                        this.pdescription = data.productdetail.description;
                        this.getParticipate();
                        // this.servertime = data.servertime;
                        this.getDetailsTime();
                        // this.getOfferProcess();
                        // this.getSignUp();
                        // this.getBidNumber();
                        // if (this.dataForm.outbidstate === '4' || this.dataForm.outbidstate === '3') {
                        //     this.timeGap = parseInt((new Date(this.servertime).getTime() - Date.now()) / 1000);
                        //     this.startCountdown();
                        //     if (this.getUser()) {
                        //         this.CheckSignUpstates();
                        //         if (this.dataForm.outbidstate === '3') {
                        //             this.getMyPrice()
                        //             this.getPriceCallList()
                        //         }
                        //     }
                        //     this.biddingState = false;
                        // } else {
                        //     this.biddingState = true;
                        //     this.getParticipate();
                        // }
                    } else {
                        this.$message({
                            message: data.msg,
                            offset: 130,
                            type: 'error',
                            showClose: 'true'
                        })
                    }


                })
            },
            //竞价单位
            getParticipate() {
                const param = {
                    targetid: this.dataForm.targetid,
                };
                this.$post('pcoutbidresultinfo/selectOutbidresultinfoList', param).then((data) => {
                    if (data && data.code === 0) {
                        this.participate = data.data;
                    } else {
                        this.$message({
                            message: data.msg,
                            offset: 130,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            // 点击导出承诺函word
            exportWordNotice: function () {
                let _this = this;
                let wordForm = {
                    bidname: _this.bidname,
                    detailsStartTime: _this.detailsStartTime,
                    detailsEndTime: _this.detailsEndTime,
                    productname: _this.productInformation[0].productname,
                    quantity: _this.productInformation[0].quantity,
                    price: _this.productInformation[0].price,
                    description1: _this.pdescription,
                    description2: _this.description,
                    detailsCreatTime: _this.detailsCreatTime,
                    data01:_this.biddingRules[0].data01,
                    trtime: _this.transactionAttributes[0].trtime,
                }
                let table1 = _this.setIndex(_this.transactionNoticeTemp);
                let table2 = _this.setIndex(_this.productInformation);
                let table3 = _this.setIndex(_this.participate);
                // 读取并获得模板文件的二进制内容
                JSZipUtils.getBinaryContent("testNotice.docx", function (error, content) {
                    // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
                    // 抛出异常
                    if (error) {
                        throw error;
                    }
                    // 创建一个JSZip实例，内容为模板的内容
                    let zip = new JSZip(content);
                    // 创建并加载docxtemplater实例对象
                    let doc = new docxtemplater().loadZip(zip);
                    // 设置模板变量的值
                    doc.setData({
                        ...wordForm,
                        table1: table1,
                        table2: table2,
                        table3: table3,
                    });

                    try {
                        // 用模板变量的值替换所有模板变量
                        doc.render();
                    } catch (error) {
                        // 抛出异常
                        let e = {
                            message: error.message,
                            name: error.name,
                            stack: error.stack,
                            properties: error.properties
                        };
                        console.log(JSON.stringify({error: e}));
                        throw error;
                    }

                    // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                    let out = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    });
                    // 将目标文件对象保存为目标类型的文件，并命名
                    saveAs(out, "承诺函（"+_this.bidname+"）.docx");
                });
            },

            setIndex(arr){
                for(let i = 0;i<arr.length;i++){
                    this.$set(arr[i],'index',i+1)
                }
                return arr;
            },
            

        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/zxXiangQing.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }
</style>
